.App {
  text-align: center;
  font-family: 'Karla', serif;
  line-height: 150%;
}

.section {
  padding: 50px 5%;
}

h2, h3 {
  font-family: 'Spectral', serif;
}

h2 {
  font-size: 2.3em;
}

h3 {
  font-size: 1.8em;
}

a:hover, a:visited {
  text-decoration: none;
}

a {
  text-decoration: none;
  font-family: 'Karla', serif;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.bdg {
  border: 1px solid green;
}

.bdr {
  border: 1px solid red;
}

@media screen and (max-width: 600px) {
  /* .section {
    padding: 0;
  } */
  #App {
    max-width: 100%;
  }
}
